import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, FormLabel } from 'react-bootstrap';
import { DurationSelector } from '@/core/DurationSelector.atom';
import { LiveUpdateRate } from '@/schedule/schedule.types';

interface LiveScheduleProps {
  rate: LiveUpdateRate;
  onChange: (liveUpdateRate: LiveUpdateRate) => void;
  onInvalidInput: (message: string) => void;
  showHelp?: boolean;
}

export const LiveSchedule: React.FunctionComponent<LiveScheduleProps> = ({
  rate: { unit, value },
  onChange,
  onInvalidInput,
  showHelp = true,
}) => {
  const { t } = useTranslation();

  return (
    <FormGroup data-testid="live-schedule-config">
      <FormLabel>{t('SCHEDULE_TYPE.LIVE.RATE.NAME')}</FormLabel>
      {showHelp && <p className="text-italic sq-darkish-gray">{t('SCHEDULE_TYPE.LIVE.RATE.DESCRIPTION')}</p>}
      <DurationSelector
        unit={unit}
        value={value}
        onChange={(value, unit) => onChange({ value, unit })}
        onInvalidInput={onInvalidInput}
      />
    </FormGroup>
  );
};

// @ts-strict-ignore
import React from 'react';
import classNames from 'classnames';
// Sets up some window globals for react-time-input-polyfill
import './timePolyfillHelpers';
import TimeInputPolyfill from 'react-time-input-polyfill';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'react-bootstrap';
import { Icon } from '@/core/Icon.atom';
import { AutoUpdateTimeScheduleEntry } from '@/schedule/schedule.types';

interface AutoUpdateTimeSchedulePropertiesProps {
  isInvalid: boolean;
  showRemoveIcon: boolean;
  entry: AutoUpdateTimeScheduleEntry;
  onChange: (updateTime: AutoUpdateTimeScheduleEntry) => void;
  onClickRemove: () => void;
}

export const AutoUpdateTimeScheduleProperties: React.FunctionComponent<AutoUpdateTimeSchedulePropertiesProps> = ({
  entry,
  onChange,
  isInvalid,
  showRemoveIcon,
  onClickRemove,
}) => {
  const { t } = useTranslation();

  const onChangeTime = (newTime: string) => {
    if (newTime) {
      return onChange({ ...entry, time: newTime });
    }
  };

  return (
    <FormGroup className="flexColumnContainer" data-testid="time-schedule-entry">
      <label className="flexColumnContainer">
        <span className="reportAutoUpdateTimeStrategy mr5">
          {t('REPORT.MODAL.AUTO_UPDATE.TIME.STRATEGIES.UPDATE_AT')}
        </span>
        <TimeInputPolyfill
          data-testid="time-schedule-entry__time"
          className={classNames('noValidationIcons width-125 mr5 form-control', isInvalid ? 'is-invalid' : '')}
          value={entry.time}
          onChange={(e: any) => onChangeTime(e.value)}
          required={true}
        />
      </label>
      {showRemoveIcon && (
        <Icon
          testId="time-schedule-entry__remove"
          extraClassNames="reportAutoUpdateTimeStrategy cursorPointer"
          onClick={onClickRemove}
          icon="fa fa-close removeButton ng-scope sq-fairly-dark-gray"
          large={true}
        />
      )}
    </FormGroup>
  );
};

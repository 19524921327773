// @ts-strict-ignore
import React, { useState } from 'react';
import { AutoUpdateTimeScheduleProperties } from '@/schedule/AutoUpdateTimeScheduleProperties.molecule';
import { Form, FormGroup } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TimeZoneSelector from '@/core/TimeZoneSelector.molecule';
import { FakeLink } from '@/core/FakeLink';
import { Timezone } from '@/datetime/timezone.service';
import { AutoUpdateTimeScheduleEntry } from '@/schedule/schedule.types';
import { DEFAULT_TIME_ENTRY } from '@/schedule/schedule.constants';
import { determineInvalidEntries } from '@/schedule/schedule.utilities';

interface AutoUpdateTimeScheduleProps {
  timezone?: Timezone;
  setTimezone?: (timezone: Timezone) => void;
  entries?: AutoUpdateTimeScheduleEntry[];
  setEntries: (entries: AutoUpdateTimeScheduleEntry[]) => void;
}

export const AutoUpdateTimeSchedule: React.FunctionComponent<AutoUpdateTimeScheduleProps> = ({
  timezone,
  setTimezone = _.noop,
  entries,
  setEntries,
}) => {
  const sortByLocalTime = (entries) => {
    return _.chain(entries).sortBy(['time']).value();
  };

  const setAllEntries = (entries) => {
    setEntries(entries);
    setLocalTimeEntries(entries);
  };

  const onEntryChange = (targetIndex: number, updatedEntry: AutoUpdateTimeScheduleEntry) => {
    const updatedEntries = _.map(localTimeEntries, (existingEntry, index) =>
      index === targetIndex ? updatedEntry : existingEntry,
    );
    setAllEntries(updatedEntries);
  };

  const insertDefaultTimeEntry = () => {
    const updatedEntries = [
      ..._.cloneDeep(localTimeEntries),
      {
        ...DEFAULT_TIME_ENTRY,
      },
    ];
    setAllEntries(updatedEntries);
  };

  const removeTimeEntry = (targetIndex: number) => {
    const updatedEntries = localTimeEntries.filter((entry, index) => index !== targetIndex);
    setAllEntries(updatedEntries);
  };

  const { t } = useTranslation();
  const [localTimeEntries, setLocalTimeEntries] = useState(sortByLocalTime(entries));

  const isEntryInvalid = determineInvalidEntries(localTimeEntries);

  return (
    <FormGroup className="flexColumnContainer" data-testid="autoUpdateTimeSchedule">
      <div className="flexRowContainer flexNoShrink mr15">
        {timezone && (
          <FormGroup className="flexColumnContainer">
            <Form.Label className="reportAutoUpdateTimezone mr5">
              {t('REPORT.MODAL.AUTO_UPDATE.TIME.TIME_ZONE')}
            </Form.Label>
            <TimeZoneSelector extraClassNames="width-220" timezone={timezone} onSelect={setTimezone} />
          </FormGroup>
        )}
        {_.map(localTimeEntries, (entry, index) => (
          <AutoUpdateTimeScheduleProperties
            key={`entry${index}`}
            isInvalid={isEntryInvalid[index]}
            showRemoveIcon={localTimeEntries.length > 1}
            entry={entry}
            onChange={(entry) => onEntryChange(index, entry)}
            onClickRemove={() => removeTimeEntry(index)}
          />
        ))}
        <Form.Label>
          <FakeLink extraClassNames="link-no-focus link-no-underline" onClick={insertDefaultTimeEntry}>
            {t('REPORT.MODAL.AUTO_UPDATE.TIME.ADD_ANOTHER_TIME')}
          </FakeLink>
        </Form.Label>
      </div>
    </FormGroup>
  );
};
